// customizations must come before bootstrap import


$primary:#002563;
$danger:#a92f3f;

/* begin general styles */

h1, h2, h3, h4, h5, h6 {
	font-weight: bold !important;
}

h1 {
	font-size: 2rem !important;
}

h2 {
	font-size: 1.5rem !important;
}

h3 {
	font-size: 1.25rem !important;
}

button.btn-primary {
	background-color: #3f51b5;
	text-transform: uppercase;
}

/* end general styles */

/* footer */

footer p {
	color: white;
}

/* begin search styles */

/* search form */

div#view-options a {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.54);
	font-weight: bold;
}

div#view-options a.active-view-mode {
	color: #3f51b5;
}

/* filters */

div#facets .accordion-item {
	border: none;
}

div#facets .accordion-button {
	background: none;
	font-weight: bold;
	border-radius: none;
	box-shadow: none;
	color: black;
}

div#facets .accordion-item ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* search results */

div#list-search-results .table > tbody > tr > td,
div#list-search-results .table > thead > tr > th {
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}

div#list-search-results .table > :not(:first-child) {
    border-top: none;
}

table.table-bordered thead tr {
	background-color: rgba(224, 224, 224, 1);
	border: none;
}

table.table-bordered thead tr th {
	padding: 16px 10px 16px 10px;
	font-weight: normal;
}
table.table-bordered tbody tr td {
	padding: 10px;
}

/* end search styles */

/* begin navbar styles */

nav.navbar-dark .navbar-brand {
    font-weight: bold;
	font-size: 1.5rem;
}

nav.navbar-dark .currentEnvironment {
	color: white;
}

nav.navbar-dark .navbar-nav a.nav-link,
nav.navbar-dark .navbar-nav .nav-item button {
    font-weight: bold;
	color: #9ad8fd;
	font-size: 1rem;
	margin-left: 12px;
	margin-right: 12px;
}

/* end navbar styles */

/* end customizations */

@import "~bootstrap/scss/bootstrap.scss";

.searchRow {
	margin: 20px 0;
}

ul.pagination {
	margin-bottom: 0;
}

/* Responsive table styling. See documentation at https://www.icpsr.umich.edu/web/pages/ICPSR/styleguide/tables.html */

table.table-bordered {
	border: none;
}

table thead {
	background: #d3dde6;
}

.table-bordered>tbody>tr>td, 
.table-bordered>tbody>tr>th, 
.table-bordered>tfoot>tr>td, 
.table-bordered>tfoot>tr>th, 
.table-bordered>thead>tr>td, 
.table-bordered>thead>tr>th {
	border-left: none;
	border-right: none;
}

@media(max-width: 767px){
	
	.table-responsive th,
	.table-responsive td {
    		width: 100%;
	}
	
	.table-responsive tr,
    .table-responsive td {
            display: block;
    }

    .table-responsive td[data-label]:before {
	    margin-bottom: 1.07142857em;
	    font-size: 0.875em;
	    font-weight: 600;
	    letter-spacing: 1px;
	    line-height: 1.25;
	    text-transform: uppercase;
	    display: block;
	    margin-top: 0;
	    margin-bottom: 0.41666667em;
	    content: attr(data-label);
	    line-height: 1.83333333;
	}

	.table-responsive > thead {
    	display: none;
	}

	.table-striped>tbody>tr:nth-of-type(odd) {
		background-color: #fff;
	}

	.table-no-repeat-headers > thead {
    	display: none;
	}

	.table-no-repeat-headers > thead {
		display: none;
	}

	.table-no-repeat-headers th,
	.table-no-repeat-headers td {
    		width: 100%;
	}

	.table-no-repeat-headers tr,
    .table-no-repeat-headers td {
            display: block;
    }

	.table-no-repeat-headers > tbody td:first-child {
	    padding-bottom: 0.75em;
	    border-bottom: 1px solid #5a5d61;
	    margin-bottom: 0.625em;
	    margin-top: 0;
	    background-color: #f7f8f9;
	    font-size: 1.125em;
	    font-weight: 400;
	    line-height: 1.22222222;
	}

	.table-no-repeat-headers td[data-label]:before {
	    margin-bottom: 1.07142857em;
	    font-size: 0.875em;
	    font-weight: 600;
	    letter-spacing: 1px;
	    line-height: 1.25;
	    text-transform: uppercase;
	    display: block;
	    margin-top: 0;
	    margin-bottom: 0.41666667em;
	    content: attr(data-label);
	    line-height: 1.83333333;
	}

	.table-no-repeat-headers tbody td:first-child:before {
            display: none;
    }

    .table-no-repeat-headers.table-bordered>tbody>tr>td, 
	.table-no-repeat-headers.table-bordered>tbody>tr>th, 
	.table-no-repeat-headers.table-bordered>tfoot>tr>td, 
	.table-no-repeat-headers.table-bordered>tfoot>tr>th, 
	.table-no-repeat-headers.table-bordered>thead>tr>td, 
	.table-no-repeat-headers.table-bordered>thead>tr>th {
		border: none;
	}

	.table-no-repeat-headers.table-bordered>tbody>tr>td:first-child, 
	.table-no-repeat-headers.table-bordered>tfoot>tr>td:first-child, 
	.table-no-repeat-headers.table-bordered>thead>tr>td:first-child {
		border-bottom: 1px solid #5a5d61;
	}

}

.header-top .login li {
  list-style: none;
}

.header-top .login a.nav-link {
  background: #fcf4c7;
  color: #002563;
  border: 1px solid #115BFB;
  padding: 2px 14px;
  text-shadow: none;
  text-decoration: none;
  border-radius: 8px;
  display: inline-block;
}

.header-top .login .btn {
  background: #fcf4c7;
  color: #002563;
  padding: 2px 14px;
}

.header-top .login .dropdown-menu li {
  border-bottom: 1px solid #d9d9d9;
}
.header-top .login .dropdown-menu li a:hover,
.header-top .login .dropdown-menu li a:focus {
  color: #b81e3c;
}

.header-top .login .nav-link.bg-nav-icon-user {
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 8.9375C10.3291 8.9375 12.2188 7.04785 12.2188 4.71875C12.2188 2.38965 10.3291 0.5 8 0.5C5.6709 0.5 3.78125 2.38965 3.78125 4.71875C3.78125 7.04785 5.6709 8.9375 8 8.9375ZM11.75 9.875H10.1357C9.48535 10.1738 8.76172 10.3438 8 10.3438C7.23828 10.3438 6.51758 10.1738 5.86426 9.875H4.25C2.17871 9.875 0.5 11.5537 0.5 13.625V14.0938C0.5 14.8701 1.12988 15.5 1.90625 15.5H14.0938C14.8701 15.5 15.5 14.8701 15.5 14.0938V13.625C15.5 11.5537 13.8213 9.875 11.75 9.875Z'  fill='%23002563'/%3E%3C/svg%3E");
  padding-left: 2.5rem;
  box-sizing: content-box;
  background-position: 1rem;
  background-repeat: no-repeat;
}

.header-top .login .dropdown-item {
  color: #002563;
}
